body {
    padding: 2rem;
    font-family: sans-serif;
    text-align: center;
  }
  
  .label {
    margin-bottom: 0.5rem;
  }
  
  .modes {
    margin-bottom: 1rem;
  }
  
  .button-container {
    margin-bottom: 0.5rem;
  }
  
  .fade-enter {
    opacity: 0;
    transform: translateX(200%);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0.9;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 500ms, transform 500ms;
  }
  